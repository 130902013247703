import React from 'react'
import man from './man.png'
import logofull from './logofull.png'
import facebook from './facebook.png'
import discord from './discord.png'
import instagram from './instagram.png'
import medium from './medium.png'
import reddit from './reddit.png'
import telegram from './telegram.png'
import twitter from './twitter.png'
import youtube from './youtube.png'
import PrivacyPolicy from './PrivacyPolicy.pdf'
import TermsAndConditions from './TermsAndConditions.pdf'
import './Footer.css'
const Footer = () => {
    return (

        <div class="footerNewMain">
            <div className='footerinner'>
                <div className='footerinner1'>
                    <div className='footerlogo'>
                        <a href="http://deta.io">
                        <img src={logofull} alt="" />
                        </a>
                    </div>
                    <div className='footertxt1'>
                        "Deta Finance: Empowering the future of decentralized finance. Unlock innovative DeFi tools, harness AI developer capabilities, and trade with confidence on our advanced platforms. Experience seamless integration, robust security, and unparalleled support. Join us and embrace a new era of financial freedom. Discover the power of Deta Finance today."
                    </div>
                    <div className='footertxt2'>
                        <span>UNIFLOW</span>|
                        <span>CONX</span>|
                        <span>PROX</span>|
                        <span>SWAP 2.0</span>|
                        <span>HARDWARE WALLET</span>|
                        <span>WALLET</span>
                    </div>

                </div>
                <div className='footerinner2'>
                    <div className='footerinner2-in'>
                        <img src={man} alt="" />

                    </div>
                </div>

                <div className='footerinner3'>
                    <div>
                        Follow us
                    </div>
                    <div className='socialIconsDiv'>
                        <div className='SocialIconHoldingDiv'>
                            <img src={facebook} alt="" />
                        </div>
                        <div className='SocialIconHoldingDiv'>
                            <img src={twitter} alt="" />
                        </div>
                        <div className='SocialIconHoldingDiv'>
                            <img src={instagram} alt="" />
                        </div>
                        <div className='SocialIconHoldingDiv'>
                            <img src={medium} alt="" />
                        </div>
                        <div className='SocialIconHoldingDiv'>
                            <img src={reddit} alt="" />
                        </div>
                        <div className='SocialIconHoldingDiv'>
                            <img src={discord} alt="" />
                        </div>
                        <div className='SocialIconHoldingDiv'>
                            <img src={telegram} alt="" />
                        </div>
                        <div className='SocialIconHoldingDiv'>
                            <img src={youtube} alt="" />
                        </div>

                    </div>
                    <div className='doublecol'>
                        <div>
                            <span className='doubleColHead'> Explore </span>
                            <div className='margintop30'>
                                <p>Token</p>
                                <p>Security</p>
                                <p>Docs</p>
                                <p>Blogs</p>
                                <p>Careers</p>
                            </div>
                        </div>
                        <div className='doubleCol2nd'>
                            <span className='doubleColHead'> Explore </span>
                            <div className='margintop30'>
                                <div className='margintop30 footertxt3-1'>
                                    <a className='zeromargin footertxt3-1' ><p onClick={() => window.open(PrivacyPolicy)} className='footertxt3-1'>Privacy Policy</p></a>
                                    <a className='zeromargin footertxt3-1' ><p onClick={() => window.open(TermsAndConditions)} className='footertxt3-1'>Terms and Conditions</p></a>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div className='footertxt3'>
                    Copyright © 2023 Deta Finance. All Rights Reserved.
                </div>
            </div>
        </div>
    )
}

export default Footer