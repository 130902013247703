import { useState } from 'react';
import '../../../common.bebaa1dc.css'
import '../../../index.cb8f1842.css'
import Footer from '../Footer/Footer';



function LandingPage() {
    const [activeTab, setActiveTab] = useState('app');
    const [LanguageDropDown, setLanguageDropDown] = useState(false)
    const [collapsed, setcollapsed] = useState(true)
    const handleCollapse = () => {
        setcollapsed(!collapsed)
    }
    const handleLanguageDropDown = () => {
        setLanguageDropDown(!LanguageDropDown)
    }
    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };

    return (

        <div>
            <div>
                <nav className="navbar Font-gotham fixed-top navbar-expand-lg com-nav"><a className="navbar-brand app-logo" href="https://deta.io/"><img src={require('../../staticcdn2.maiziqianbao.net/static/img/DETAlogo.png')} /> </a>
                    <button onClick={handleCollapse} className={`navbar-toggler ${collapsed ? 'collapsed' : ''} `} type="button" data-toggle="collapse" data-target="#toggleNav"><span className="navbar-toggler-icon" /></button>
                    <div className={`collapse navbar-collapse justify-content-end ${!collapsed ? 'show' : ''}`} id="toggleNav">
                        <div className={`collapse-close d-lg-none ${collapsed ? 'collapsed' : ''}`} data-toggle="collapse" onClick={handleCollapse} data-target="#toggleNav">×</div>
                        <ul className="navbar-nav">
                            <li className="d-lg-none"><a >Home</a>
                            </li>
                            <li><a target="_blank" rel="noreferrer" >Developer</a>
                            </li>

                            <li><a  target="_blank" rel="noreferrer">Help</a></li>
                            <li className={`nav-item dropdown ${LanguageDropDown ? 'show' : ''}`}><a href="javascript:;" className="dropdown-toggle" onClick={handleLanguageDropDown} id="lang" data-toggle="dropdown">English</a>
                                <div className={`dropdown-menu ${LanguageDropDown ? 'show' : ''}`} aria-labelledby="lang"><a className="dropdown-item" >English</a> <a className="dropdown-item" >中文</a> <a className="dropdown-item" >日本語</a> <a className="dropdown-item" >한국어</a> <a className="dropdown-item" >Indonesia</a>
                                    <a className="dropdown-item" >Português</a> <a className="dropdown-item" >русский</a> <a className="dropdown-item" >عربي</a>
                                </div>
                            </li>
                        </ul>
                    </div>
                </nav>
                <section>
                    <div className="container">
                        <header className="website-header">
                            <div className="row justify-content-md-center">
                                <div className="col-md-10 col-lg-8 ">
                                    <ul className="nav nav-justified Navigation-tabs-Landing" id="headerTab" >
                                        <li className="nav-item">
                                            <a
                                                className={`nav-link IconHoverDull1 ${activeTab === 'app' ? 'active' : ''}`}
                                                // href="#app"
                                                onClick={() => handleTabClick('app')}
                                            >
                                                <p>App</p>
                                            </a>
                                        </li>
                                        <li className="nav-item">
                                            <a
                                                className={`nav-link IconHoverDull2 ${activeTab === 'extension' ? 'active' : ''}`}
                                                // href="#extension"
                                                onClick={() => handleTabClick('extension')}
                                            >
                                                Extension
                                            </a>
                                        </li>
                                        <li className="nav-item">
                                            <a
                                                className={`nav-link IconHoverDull3 ${activeTab === 'web' ? 'active' : ''}`}
                                                // href="#web"
                                                onClick={() => handleTabClick('web')}
                                            >
                                                Web Wallet
                                            </a>
                                        </li>

                                    </ul>
                                    <div className="tab-content download-btn">
                                        <div className={`tab-pane  row ${activeTab === 'app' ? 'active' : ''}`} id="app">
                                            <div className="col-md-7">
                                                <h1>The Multichain Wallet for Web3</h1>
                                                <ul className="install-box app-install">
                                                    {/* <li className="d-none d-md-block"><a href="javascript:;"><img src="https://staticcdn2.maiziqianbao.net/static/img/icon/foriphone@2x.png" width={24} /> <span>iPhone App</span></a></li>
                                                    <li className="d-none d-md-block"><a className="downloadAndroid" href="https://play.google.com/store/apps/details?id=com.mathwallet.android" target="_blank" rel="noreferrer"><img src="https://staticcdn2.maiziqianbao.net/static/img/icon/forandroid@2x.png" width={24} /> <span>Android App</span></a></li> */}
                                                    <li className="d-none d-md-block"><a href="javascript:;"><img className='downloadIconsImage' src={require("../../staticcdn2.maiziqianbao.net/static/img/appstore.webp")} width={100} /> </a></li>
                                                    <li className="d-none d-md-block"><a target='_blank' ><img className='downloadIconsImage' src={require("../../staticcdn2.maiziqianbao.net/static/img/playstore.webp")} width={100} /> </a></li>

                                                </ul>
                                                <ul className="install-box" id="collapseApp">
                                                    {/* <li className="d-md-none"><a className="install-iphone" href="javascript:;" data-toggle="modal"><img src="https://staticcdn2.maiziqianbao.net/static/img/icon/foriphone@2x.png" width={24} /> <span>iPhone App</span></a></li>
                                                    <li className="d-md-none"><a className="install-android" href="javascript:;" data-toggle="modal"><img src="https://staticcdn2.maiziqianbao.net/static/img/icon/forandroid@2x.png" width={24} /> <span>Android App</span></a></li> */}

                                                    <li className="d-md-none"><a href="javascript:;"><img className='downloadIconsImage' src={require("../../staticcdn2.maiziqianbao.net/static/img/appstore.webp")} width={100} /> </a></li>
                                                    <li className="d-md-none"><a href="javascript:;"><img className='downloadIconsImage' src={require("../../staticcdn2.maiziqianbao.net/static/img/playstore.webp")} width={100} /> </a></li>
                                                </ul>
                                                <div className="modal modal-collapse" id="collapseiPhone" aria-hidden="true">
                                                    <div className="modal-dialog" role="document">
                                                        <div className="modal-content"><button type="button" className="close" data-dismiss="modal"><img src="https://staticcdn2.maiziqianbao.net/static/img/app/dowmward.png" width={16} /></button>
                                                            <div className="text-center"><img src="https://staticcdn2.maiziqianbao.net/static/img/app/math5.png" width={60} />
                                                                <h4>MathWallet</h4>
                                                            </div><a className="d-btn downloadIos" target="_blank" rel="noreferrer" ><img src="https://staticcdn2.maiziqianbao.net/static/img/app/appstore.png" width={24} /> <span>AppStore Version</span></a></div>
                                                    </div>
                                                </div>
                                                <div className="modal modal-collapse" id="collapseAndroid" aria-hidden="true">
                                                    <div className="modal-dialog" role="document">
                                                        <div className="modal-content"><button type="button" className="close" data-dismiss="modal"><img src="https://staticcdn2.maiziqianbao.net/static/img/app/dowmward.png" width={16} /></button>
                                                            <div className="text-center"><img src="https://staticcdn2.maiziqianbao.net/static/img/app/math5_android.png" width={60} />
                                                                <h4>MathWallet</h4>
                                                            </div><a className="downloadAndroid d-btn border" target="_blank" rel="noreferrer" ><img src="https://staticcdn2.maiziqianbao.net/static/img/app/google.png" width={24} /> <span>GooglePlay</span> </a>
                                                            <a className="downloadAndroid d-btn"  ><img src="https://staticcdn2.maiziqianbao.net/static/img/app/android-green.png" width={24} /> <span>Download APK</span></a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="modal modal-guide" id="guideModal2">
                                                    <div className="modal-dialog" role="document">
                                                        <div className="modal-content"><button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span></button> <a className="downloadIos install" >AppStore Version</a>
                                                            <div className="guide-info" style={{ border: 'none' }}>
                                                                <p style={{ fontSize: '14px' }}>在海外 AppStore 下载应用需要海外 Apple ID</p>
                                                                <p style={{ fontSize: '14px', padding: '8px 0 16px', borderBottom: '1px solid #dedede' }}><a  target="_blank" rel="noreferrer">点击查看如何创建海外 Apple ID？</a></p>
                                                                <h5 style={{ marginTop: '32px' }}>1. 点击 AppStore 右上角头像</h5>
                                                                <p><img src="https://staticcdn2.maiziqianbao.net/static/img/app/guide1.png" width="100%" /></p>
                                                                <h5>2. 进入账户后，点击退出登录</h5>
                                                                <p><img src="https://staticcdn2.maiziqianbao.net/static/img/app/guide2.png" width="100%" /></p>
                                                                <h5>3. 使用海外 AppStore 账号进行登录后，即可进行下载。</h5>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-5" style={{marginBottom:"200px"}}><img src={require("../../staticcdn2.maiziqianbao.net/static/img/ImageMobile.png")} className="phone" /></div>
                                        </div>
                                        <div className={`tab-pane row ${activeTab === 'extension' ? 'active' : ''}`} id="extension">
                                            <div className="col-md-7">
                                                <h1>Your one stop Multichain Wallet for Web3</h1>
                                                <ul className="install-box">
                                                    <li className="d-none d-md-block"><a target="_blank" rel="noreferrer" ><img src="https://staticcdn2.maiziqianbao.net/static/img/extension/Chrome.png" width={24} /> <span>Chrome browser</span></a></li>
                                                    <li className="d-none d-md-block"><a target="_blank" rel="noreferrer" ><img src="https://staticcdn2.maiziqianbao.net/static/img/extension/Brave.png" width={24} /> <span>Brave browser</span></a></li>
                                                    <li className="d-none d-md-block"><a target="_blank" rel="noreferrer" ><img src="https://staticcdn2.maiziqianbao.net/static/img/extension/Edge.png" width={24} /> <span>Edge browser</span></a></li>
                                                    {/* <li className="d-none d-md-block"><a target="_blank" rel="noreferrer" href="https://mathwallet-public.s3.us-east-2.amazonaws.com/extension/mathwallet.zip"><img src="https://staticcdn2.maiziqianbao.net/static/img/extension/Other.png" width={24} /> <span>Other browsers</span></a></li> */}
                                                </ul>
                                                <ul className="install-box">
                                                    <li className="d-md-none"><a className="install-chrome" href="javascript:;"><img src="https://staticcdn2.maiziqianbao.net/static/img/extension/Chrome.png" width={24} /> <span>Chrome browser</span></a></li>
                                                    <li className="d-md-none"><a className="install-chrome" href="javascript:;"><img src="https://staticcdn2.maiziqianbao.net/static/img/extension/Brave.png" width={24} /> <span>Brave browser</span></a></li>
                                                    <li className="d-md-none"><a className="install-chrome" href="javascript:;"><img src="https://staticcdn2.maiziqianbao.net/static/img/extension/Edge.png" width={24} /> <span>Edge browser</span></a></li>
                                                    {/* <li className="d-md-none"><a className="install-chrome" href="javascript:;"><img src="https://staticcdn2.maiziqianbao.net/static/img/extension/Other.png" width={24} /> <span>Other browsers</span></a></li> */}
                                                </ul>
                                            </div>
                                            <div className="col-md-5"><img src={require("../../staticcdn2.maiziqianbao.net/static/img/extension.png")} className="extension" /></div>
                                        </div>
                                        <div className={`tab-pane ${activeTab === 'web' ? 'active' : ''}`} id="web">
                                            <div className="web-box">
                                                <h1>The Multichain Wallet for Web3</h1>
                                                <ul className="install-box remove-links-items">
                                                    <li className="cloudwallet"><a rel="noreferrer" ><img src="https://staticcdn2.maiziqianbao.net/static/img/blockchain/cloudwallet.png" width={24} /> <span>Cloud Wallet</span></a>
                                                    </li>
                                                    <li><a rel="noreferrer" ><img src="https://staticcdn2.maiziqianbao.net/static/img/blockchain/bitcoin.png" width={24} /> <span>Bitcoin Wallet</span></a></li>
                                                    <li><a rel="noreferrer" ><img src="https://staticcdn2.maiziqianbao.net/static/img/blockchain/ethereum.png" width={24} /> <span>Ethereum Wallet</span></a></li>
                                                    <li><a rel="noreferrer" ><img src="https://staticcdn2.maiziqianbao.net/static/img/blockchain/tron.png" width={24} /> <span>TRON Wallet</span></a></li>
                                                    <li><a rel="noreferrer" ><img src="https://staticcdn2.maiziqianbao.net/static/img/blockchain/binance.png" width={24} /> <span>Binance Wallet</span></a></li>
                                                    <li><a rel="noreferrer" ><img src="https://staticcdn2.maiziqianbao.net/static/img/blockchain/cosmos.png" width={24} /> <span>Cosmos Wallet</span></a></li>
                                                    <li><a rel="noreferrer" ><img src="https://staticcdn2.maiziqianbao.net/static/img/blockchain/polkadot.png" width={24} /> <span>Polkadot Wallet</span></a></li>
                                                    <li><a rel="noreferrer" ><img src="https://staticcdn2.maiziqianbao.net/static/img/blockchain/solana.png" width={24} /> <span>Solana Wallet</span></a></li>
                                                    <li><a rel="noreferrer" ><img src="https://staticcdn2.maiziqianbao.net/static/img/blockchain/harmony.png" width={24} /> <span>Harmony Wallet</span></a></li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className={`tab-pane row text-center ${activeTab === 'hardware' ? 'active' : ''}`} id="hardware">
                                            <div className="col-md-6"><img src="https://staticcdn2.maiziqianbao.net/static//img/bg/ledger.jpg" width="100%" />
                                                <h3>Ledger</h3>
                                                <p>* supports MathWallet Google Chrome Extension</p>
                                                <ul className="install-box">
                                                    <li><a target="_blank" rel="noreferrer" ><span>Buy now</span></a></li>
                                                </ul>
                                            </div>
                                            <div className="col-md-6"><img src="https://staticcdn2.maiziqianbao.net/static//img/bg/wookong.jpg" width="100%" />
                                                <h3>WOOKONG Bio</h3>
                                                <p>* supports MathWallet App</p>
                                                <ul className="install-box">
                                                    <li><a href="javascript:;" style={{ background: '#8a8a8f' }}><span>Sold out</span></a></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </header>
                        <main className="support-token">
                            <h1 className="part-title">157+ Popular public chains supported</h1>
                            <h4>Mainstream</h4>
                            <ul className="token-list row remove-links-items">
                                <li className="col-lg-3 col-md-4 col-sm-6"><a rel="noreferrer">Bitcoin</a></li>
                                <li className="col-lg-3 col-md-4 col-sm-6"><a rel="noreferrer">Solana</a></li>
                                <li className="col-lg-3 col-md-4 col-sm-6"><a rel="noreferrer">APTOS</a></li>
                                <li className="col-lg-3 col-md-4 col-sm-6"><a rel="noreferrer">Filecoin</a></li>
                                <li className="col-lg-3 col-md-4 col-sm-6"><a rel="noreferrer">Arweave</a></li>
                                <li className="col-lg-3 col-md-4 col-sm-6"><a rel="noreferrer">Tezos</a></li>
                                <li className="col-lg-3 col-md-4 col-sm-6"><a rel="noreferrer">Flow</a></li>
                                <li className="col-lg-3 col-md-4 col-sm-6"><a rel="noreferrer">TRON</a></li>
                                <li className="col-lg-3 col-md-4 col-sm-6"><a rel="noreferrer">Near</a></li>
                                <li className="col-lg-3 col-md-4 col-sm-6"><a rel="noreferrer">EOS</a></li>
                                <li className="col-lg-3 col-md-4 col-sm-6"><a rel="noreferrer">BNB Beacon Chain</a></li>
                                <li className="col-lg-3 col-md-4 col-sm-6"><a rel="noreferrer">Conflux</a></li>
                                <li className="col-lg-3 col-md-4 col-sm-6"><a rel="noreferrer">Nervos</a></li>
                                <li className="col-lg-3 col-md-4 col-sm-6"><a rel="noreferrer">Ontology</a></li>
                                <li className="col-lg-3 col-md-4 col-sm-6"><a rel="noreferrer">VeChain</a></li>
                                <li className="col-lg-3 col-md-4 col-sm-6"><a rel="noreferrer">Harmony</a></li>
                                <li className="col-lg-3 col-md-4 col-sm-6"><a rel="noreferrer">Substrate</a></li>
                                <li className="col-lg-3 col-md-4 col-sm-6"><a rel="noreferrer">EVM</a></li>
                                <li className="col-lg-3 col-md-4 col-sm-6"><a rel="noreferrer">TON</a></li>
                                <li className="col-lg-3 col-md-4 col-sm-6"><a rel="noreferrer">Sui</a></li>
                                <li className="col-lg-3 col-md-4 col-sm-6"><a rel="noreferrer">Zilliqa</a></li>
                                <li className="col-lg-3 col-md-4 col-sm-6"><a rel="noreferrer">EOS FORCE</a></li>
                                <li className="col-lg-3 col-md-4 col-sm-6"><a rel="noreferrer">YAS</a></li>
                                <li className="col-lg-3 col-md-4 col-sm-6"><a rel="noreferrer">FIBOS</a></li>
                                <li className="col-lg-3 col-md-4 col-sm-6"><a rel="noreferrer">Enumivo</a></li>
                                <li className="col-lg-3 col-md-4 col-sm-6"><a rel="noreferrer">BOS</a></li>
                                <li className="col-lg-3 col-md-4 col-sm-6"><a rel="noreferrer">TelosEOS</a></li>
                                <li className="col-lg-3 col-md-4 col-sm-6"><a rel="noreferrer">NEO</a></li>
                                <li className="col-lg-3 col-md-4 col-sm-6"><a rel="noreferrer">Nebulas</a></li>
                                <li className="col-lg-3 col-md-4 col-sm-6"><a rel="noreferrer">Ethersocial</a></li>
                                <li className="col-lg-3 col-md-4 col-sm-6"><a rel="noreferrer">WORBLI</a></li>
                                <li className="col-lg-3 col-md-4 col-sm-6"><a rel="noreferrer">Wax</a></li>
                                <li className="col-lg-3 col-md-4 col-sm-6"><a rel="noreferrer">NFT</a></li>
                                <li className="col-lg-3 col-md-4 col-sm-6"><a rel="noreferrer">Solana Testnet</a></li>
                            </ul>
                            <h4>EVM</h4>
                            <ul className="token-list row remove-links-items">
                                <li className="col-lg-3 col-md-4 col-sm-6"><a rel="noreferrer">Ethereum</a></li>
                                <li className="col-lg-3 col-md-4 col-sm-6"><a rel="noreferrer">BNBChain</a></li>
                                <li className="col-lg-3 col-md-4 col-sm-6"><a rel="noreferrer">Polygon</a></li>
                                <li className="col-lg-3 col-md-4 col-sm-6"><a rel="noreferrer">Arbitrum One</a></li>
                                <li className="col-lg-3 col-md-4 col-sm-6"><a rel="noreferrer">Optimism</a></li>
                                <li className="col-lg-3 col-md-4 col-sm-6"><a rel="noreferrer">Arbitrum Nova</a></li>
                                <li className="col-lg-3 col-md-4 col-sm-6"><a rel="noreferrer">Moonbeam</a></li>
                                <li className="col-lg-3 col-md-4 col-sm-6"><a rel="noreferrer">OKXChain</a></li>
                                <li className="col-lg-3 col-md-4 col-sm-6"><a rel="noreferrer">Moonriver</a></li>
                                <li className="col-lg-3 col-md-4 col-sm-6"><a rel="noreferrer">Fantom</a></li>
                                <li className="col-lg-3 col-md-4 col-sm-6"><a rel="noreferrer">Avalanche</a></li>
                                <li className="col-lg-3 col-md-4 col-sm-6"><a rel="noreferrer">Heco</a></li>
                                <li className="col-lg-3 col-md-4 col-sm-6"><a rel="noreferrer">Evmos</a></li>
                                <li className="col-lg-3 col-md-4 col-sm-6"><a rel="noreferrer">Celo</a></li>
                                <li className="col-lg-3 col-md-4 col-sm-6"><a rel="noreferrer">GnosisChain</a></li>
                                <li className="col-lg-3 col-md-4 col-sm-6"><a rel="noreferrer">Cronos</a></li>
                                <li className="col-lg-3 col-md-4 col-sm-6"><a rel="noreferrer">Metis</a></li>
                                <li className="col-lg-3 col-md-4 col-sm-6"><a rel="noreferrer">OasisNetwork</a></li>
                                <li className="col-lg-3 col-md-4 col-sm-6"><a rel="noreferrer">Syscoin</a></li>
                                <li className="col-lg-3 col-md-4 col-sm-6"><a rel="noreferrer">PlatON</a></li>
                                <li className="col-lg-3 col-md-4 col-sm-6"><a rel="noreferrer">Rootstock</a></li>
                                <li className="col-lg-3 col-md-4 col-sm-6"><a rel="noreferrer">Klaytn</a></li>
                                <li className="col-lg-3 col-md-4 col-sm-6"><a rel="noreferrer">HooSmartChain</a></li>
                                <li className="col-lg-3 col-md-4 col-sm-6"><a rel="noreferrer">Harmony EVM</a></li>
                                <li className="col-lg-3 col-md-4 col-sm-6"><a rel="noreferrer">AstarEVM</a></li>
                                <li className="col-lg-3 col-md-4 col-sm-6"><a rel="noreferrer">ShidenEVM</a></li>
                                <li className="col-lg-3 col-md-4 col-sm-6"><a rel="noreferrer">EthereumPoW</a></li>
                                <li className="col-lg-3 col-md-4 col-sm-6"><a rel="noreferrer">EthereumFair</a></li>
                                <li className="col-lg-3 col-md-4 col-sm-6"><a rel="noreferrer">zkSync Era</a></li>
                                <li className="col-lg-3 col-md-4 col-sm-6"><a rel="noreferrer">GateChain</a></li>
                                <li className="col-lg-3 col-md-4 col-sm-6"><a rel="noreferrer">KCC</a></li>
                                <li className="col-lg-3 col-md-4 col-sm-6"><a rel="noreferrer">Godwoken</a></li>
                                <li className="col-lg-3 col-md-4 col-sm-6"><a rel="noreferrer">smartBCH</a></li>
                                <li className="col-lg-3 col-md-4 col-sm-6"><a rel="noreferrer">Fuse</a></li>
                                <li className="col-lg-3 col-md-4 col-sm-6"><a rel="noreferrer">MilkomedaCardano</a></li>
                                <li className="col-lg-3 col-md-4 col-sm-6"><a rel="noreferrer">Swimmer</a></li>
                                <li className="col-lg-3 col-md-4 col-sm-6"><a rel="noreferrer">Cube Chain</a></li>
                                <li className="col-lg-3 col-md-4 col-sm-6"><a rel="noreferrer">TelosEVM</a></li>
                                <li className="col-lg-3 col-md-4 col-sm-6"><a rel="noreferrer">XDC</a></li>
                                <li className="col-lg-3 col-md-4 col-sm-6"><a rel="noreferrer">Bitgert</a></li>
                                <li className="col-lg-3 col-md-4 col-sm-6"><a rel="noreferrer">HumanodeEVM</a></li>
                                <li className="col-lg-3 col-md-4 col-sm-6"><a rel="noreferrer">Flare</a></li>
                                <li className="col-lg-3 col-md-4 col-sm-6"><a rel="noreferrer">Canto</a></li>
                                <li className="col-lg-3 col-md-4 col-sm-6"><a rel="noreferrer">ENULS</a></li>
                                <li className="col-lg-3 col-md-4 col-sm-6"><a rel="noreferrer">Conflux eSpace</a></li>
                                <li className="col-lg-3 col-md-4 col-sm-6"><a rel="noreferrer">Dexit</a></li>
                                <li className="col-lg-3 col-md-4 col-sm-6"><a rel="noreferrer">FVM</a></li>
                                <li className="col-lg-3 col-md-4 col-sm-6"><a rel="noreferrer">Scroll Alpha</a></li>
                                <li className="col-lg-3 col-md-4 col-sm-6"><a rel="noreferrer">Aurora</a></li>
                                <li className="col-lg-3 col-md-4 col-sm-6"><a rel="noreferrer">Songbird</a></li>
                                <li className="col-lg-3 col-md-4 col-sm-6"><a rel="noreferrer">CoinEx Smart Chain</a></li>
                                <li className="col-lg-3 col-md-4 col-sm-6"><a rel="noreferrer">Palm</a></li>
                                <li className="col-lg-3 col-md-4 col-sm-6"><a rel="noreferrer">Boba</a></li>
                                <li className="col-lg-3 col-md-4 col-sm-6"><a rel="noreferrer">ApeChain</a></li>
                                <li className="col-lg-3 col-md-4 col-sm-6"><a rel="noreferrer">TomoChain</a></li>
                                <li className="col-lg-3 col-md-4 col-sm-6"><a rel="noreferrer">Ethereum Classic</a></li>
                                <li className="col-lg-3 col-md-4 col-sm-6"><a rel="noreferrer">IoTeX</a></li>
                                <li className="col-lg-3 col-md-4 col-sm-6"><a rel="noreferrer">BSC Testnet</a></li>
                                <li className="col-lg-3 col-md-4 col-sm-6"><a rel="noreferrer">BAS Testnet</a></li>
                                <li className="col-lg-3 col-md-4 col-sm-6"><a rel="noreferrer">Base Goerli</a></li>
                            </ul>
                            <h4>Substrate</h4>
                            <ul className="token-list row remove-links-items">
                                <li className="col-lg-3 col-md-4 col-sm-6"><a rel="noreferrer">Polkadot</a></li>
                                <li className="col-lg-3 col-md-4 col-sm-6"><a rel="noreferrer">Kusama</a></li>
                                <li className="col-lg-3 col-md-4 col-sm-6"><a rel="noreferrer">Statemine</a></li>
                                <li className="col-lg-3 col-md-4 col-sm-6"><a rel="noreferrer">Acala</a></li>
                                <li className="col-lg-3 col-md-4 col-sm-6"><a rel="noreferrer">Karura</a></li>
                                <li className="col-lg-3 col-md-4 col-sm-6"><a rel="noreferrer">Bifrost Kusama</a></li>
                                <li className="col-lg-3 col-md-4 col-sm-6"><a rel="noreferrer">Bifrost Polkadot</a></li>
                                <li className="col-lg-3 col-md-4 col-sm-6"><a rel="noreferrer">Khala</a></li>
                                <li className="col-lg-3 col-md-4 col-sm-6"><a rel="noreferrer">Parallel</a></li>
                                <li className="col-lg-3 col-md-4 col-sm-6"><a rel="noreferrer">ChainX</a></li>
                                <li className="col-lg-3 col-md-4 col-sm-6"><a rel="noreferrer">CRUST</a></li>
                                <li className="col-lg-3 col-md-4 col-sm-6"><a rel="noreferrer">KintsugiBTC</a></li>
                                <li className="col-lg-3 col-md-4 col-sm-6"><a rel="noreferrer">Clover</a></li>
                                <li className="col-lg-3 col-md-4 col-sm-6"><a rel="noreferrer">Darwinia</a></li>
                                <li className="col-lg-3 col-md-4 col-sm-6"><a rel="noreferrer">Astar</a></li>
                                <li className="col-lg-3 col-md-4 col-sm-6"><a rel="noreferrer">Shiden</a></li>
                                <li className="col-lg-3 col-md-4 col-sm-6"><a rel="noreferrer">Calamari</a></li>
                                <li className="col-lg-3 col-md-4 col-sm-6"><a rel="noreferrer">KILT Spiritnet</a></li>
                                <li className="col-lg-3 col-md-4 col-sm-6"><a rel="noreferrer">Basilisk</a></li>
                                <li className="col-lg-3 col-md-4 col-sm-6"><a rel="noreferrer">Neatcoin</a></li>
                                <li className="col-lg-3 col-md-4 col-sm-6"><a rel="noreferrer">Quartz</a></li>
                                <li className="col-lg-3 col-md-4 col-sm-6"><a rel="noreferrer">Statemint</a></li>
                                <li className="col-lg-3 col-md-4 col-sm-6"><a rel="noreferrer">Efinity</a></li>
                                <li className="col-lg-3 col-md-4 col-sm-6"><a rel="noreferrer">Composable Finance</a></li>
                                <li className="col-lg-3 col-md-4 col-sm-6"><a rel="noreferrer">OriginTrail Parachain</a></li>
                                <li className="col-lg-3 col-md-4 col-sm-6"><a rel="noreferrer">Phala</a></li>
                                <li className="col-lg-3 col-md-4 col-sm-6"><a rel="noreferrer">UNIQUE</a></li>
                                <li className="col-lg-3 col-md-4 col-sm-6"><a rel="noreferrer">Humanode</a></li>
                                <li className="col-lg-3 col-md-4 col-sm-6"><a rel="noreferrer">Edgeware</a></li>
                                <li className="col-lg-3 col-md-4 col-sm-6"><a rel="noreferrer">Equilibrium</a></li>
                                <li className="col-lg-3 col-md-4 col-sm-6"><a rel="noreferrer">Kulupu</a></li>
                                <li className="col-lg-3 col-md-4 col-sm-6"><a rel="noreferrer">Stafi</a></li>
                                <li className="col-lg-3 col-md-4 col-sm-6"><a rel="noreferrer">Centrifuge</a></li>
                                <li className="col-lg-3 col-md-4 col-sm-6"><a rel="noreferrer">Subsocial Parachain</a></li>
                                <li className="col-lg-3 col-md-4 col-sm-6"><a rel="noreferrer">Subsocial Solochain</a></li>
                                <li className="col-lg-3 col-md-4 col-sm-6"><a rel="noreferrer">Sora</a></li>
                                <li className="col-lg-3 col-md-4 col-sm-6"><a rel="noreferrer">Darwinia Crab</a></li>
                                <li className="col-lg-3 col-md-4 col-sm-6"><a rel="noreferrer">DBC Mainnet</a></li>
                            </ul>
                            <h4>CosmosSDK</h4>
                            <ul className="token-list row remove-links-items">
                                <li className="col-lg-3 col-md-4 col-sm-6"><a rel="noreferrer">Cosmos</a></li>
                                <li className="col-lg-3 col-md-4 col-sm-6"><a rel="noreferrer">IRISnet</a></li>
                                <li className="col-lg-3 col-md-4 col-sm-6"><a rel="noreferrer">Secret Network</a></li>
                                <li className="col-lg-3 col-md-4 col-sm-6"><a rel="noreferrer">KAVA</a></li>
                                <li className="col-lg-3 col-md-4 col-sm-6"><a rel="noreferrer">THORChain</a></li>
                                <li className="col-lg-3 col-md-4 col-sm-6"><a rel="noreferrer">Band Protocol</a></li>
                                <li className="col-lg-3 col-md-4 col-sm-6"><a rel="noreferrer">Osmosis</a></li>
                                <li className="col-lg-3 col-md-4 col-sm-6"><a rel="noreferrer">Akash</a></li>
                                <li className="col-lg-3 col-md-4 col-sm-6"><a rel="noreferrer">Starname</a></li>
                                <li className="col-lg-3 col-md-4 col-sm-6"><a rel="noreferrer">Certik</a></li>
                                <li className="col-lg-3 col-md-4 col-sm-6"><a rel="noreferrer">Sentinel</a></li>
                                <li className="col-lg-3 col-md-4 col-sm-6"><a rel="noreferrer">Regen</a></li>
                                <li className="col-lg-3 col-md-4 col-sm-6"><a rel="noreferrer">Persistence</a></li>
                                <li className="col-lg-3 col-md-4 col-sm-6"><a rel="noreferrer">Crypto.org</a></li>
                                <li className="col-lg-3 col-md-4 col-sm-6"><a rel="noreferrer">Axelar</a></li>
                                <li className="col-lg-3 col-md-4 col-sm-6"><a rel="noreferrer">IXO</a></li>
                                <li className="col-lg-3 col-md-4 col-sm-6"><a rel="noreferrer">Juno</a></li>
                                <li className="col-lg-3 col-md-4 col-sm-6"><a rel="noreferrer">Stargaze</a></li>
                                <li className="col-lg-3 col-md-4 col-sm-6"><a rel="noreferrer">Sommelier</a></li>
                                <li className="col-lg-3 col-md-4 col-sm-6"><a rel="noreferrer">Gravity Bridge</a></li>
                                <li className="col-lg-3 col-md-4 col-sm-6"><a rel="noreferrer">Umee</a></li>
                                <li className="col-lg-3 col-md-4 col-sm-6"><a rel="noreferrer">Injective</a></li>
                                <li className="col-lg-3 col-md-4 col-sm-6"><a rel="noreferrer">Noble</a></li>
                                <li className="col-lg-3 col-md-4 col-sm-6"><a rel="noreferrer">Archway Constantine</a></li>
                                <li className="col-lg-3 col-md-4 col-sm-6"><a rel="noreferrer">SEI atlantic 2</a></li>
                            </ul>
                        </main>

                    </div>


                    {/* <div className="partner">
                        <div className="container">
                            <h1 className="part-title">Our Partners</h1>
                            <div className="partner-list">
                                <ul>
                                    <li><a rel="noreferrer"><img src={require("../../staticcdn2.maiziqianbao.net/static/img/partners images/1.png")} height="100%" /></a></li>
                                    <li><a rel="noreferrer"><img src={require("../../staticcdn2.maiziqianbao.net/static/img/partners images/2.png")} height="100%" /></a></li>
                                    <li><a rel="noreferrer"><img src={require("../../staticcdn2.maiziqianbao.net/static/img/partners images/3.png")} height="100%" /></a></li>
                                    <li><a rel="noreferrer"><img src={require("../../staticcdn2.maiziqianbao.net/static/img/partners images/4.png")} height="100%" /></a></li>
                                    <li><a rel="noreferrer"><img src={require("../../staticcdn2.maiziqianbao.net/static/img/partners images/5.png")} height="100%" /></a></li>
                                    <li><a rel="noreferrer"><img src={require("../../staticcdn2.maiziqianbao.net/static/img/partners images/6.png")} height="100%" /></a></li>
                                    <li><a rel="noreferrer"><img src={require("../../staticcdn2.maiziqianbao.net/static/img/partners images/7.png")} height="100%" /></a></li>
                                    <li><a rel="noreferrer"><img src={require("../../staticcdn2.maiziqianbao.net/static/img/partners images/8.png")} height="100%" /></a></li>
                                    <li><a rel="noreferrer"><img src={require("../../staticcdn2.maiziqianbao.net/static/img/partners images/9.png")} height="100%" /></a></li>


                                </ul>
                            </div>
                        </div>
                    </div> */}
                    <div className="partner remove-links-items"><div className="container"><h1 className="part-title">Our Partners</h1><div className="partner-list">
                        <ul>
                            <li><a href="javascript:;" target="_blank" rel="noreferrer"><img src={require('../../staticcdn2.maiziqianbao.net/static/img/partners images/1.png')} height="100%" /></a></li>
                            <li><a href="javascript:;"><img src={require('../../staticcdn2.maiziqianbao.net/static/img/partners images/2.png')} height="100%" /></a></li><li><a href="javascript:;"><img src={require('../../staticcdn2.maiziqianbao.net/static/img/partners images/3.png')} height="100%" /></a></li>
                            <li><a href="javascript:;"><img src={require('../../staticcdn2.maiziqianbao.net/static/img/partners images/4.png')} height="100%" /></a></li><li><a  rel="noreferrer"><img src={require('../../staticcdn2.maiziqianbao.net/static/img/partners images/5.png')} height="100%" /></a></li>
                            <li><a href="javascript:;"><img src={require('../../staticcdn2.maiziqianbao.net/static/img/partners images/6.png')} height="100%" /></a></li><li><a  rel="noreferrer"><img src={require('../../staticcdn2.maiziqianbao.net/static/img/partners images/7.png')} height="100%" /></a></li>
                            <li><a href="javascript:;"><img src={require('../../staticcdn2.maiziqianbao.net/static/img/partners images/8.png')} height="100%" /></a></li><li><a href="javascript:;"><img src={require('../../staticcdn2.maiziqianbao.net/static/img/partners images/9.png')} height="100%" /></a></li>
                            <li><a href="javascript:;" target="_blank" rel="noreferrer"><img src={require('../../staticcdn2.maiziqianbao.net/static/img/partners images/1.png')} height="100%" /></a></li>
                            <li><a href="javascript:;"><img src={require('../../staticcdn2.maiziqianbao.net/static/img/partners images/2.png')} height="100%" /></a></li><li><a href="javascript:;"><img src={require('../../staticcdn2.maiziqianbao.net/static/img/partners images/3.png')} height="100%" /></a></li>
                            <li><a href="javascript:;"><img src={require('../../staticcdn2.maiziqianbao.net/static/img/partners images/4.png')} height="100%" /></a></li><li><a  rel="noreferrer"><img src={require('../../staticcdn2.maiziqianbao.net/static/img/partners images/5.png')} height="100%" /></a></li>
                            <li><a href="javascript:;"><img src={require('../../staticcdn2.maiziqianbao.net/static/img/partners images/6.png')} height="100%" /></a></li><li><a  rel="noreferrer"><img src={require('../../staticcdn2.maiziqianbao.net/static/img/partners images/7.png')} height="100%" /></a></li>
                            <li><a href="javascript:;"><img src={require('../../staticcdn2.maiziqianbao.net/static/img/partners images/8.png')} height="100%" /></a></li><li><a href="javascript:;"><img src={require('../../staticcdn2.maiziqianbao.net/static/img/partners images/9.png')} height="100%" /></a></li>
                            <li><a href="javascript:;" target="_blank" rel="noreferrer"><img src={require('../../staticcdn2.maiziqianbao.net/static/img/partners images/1.png')} height="100%" /></a></li>
                            <li><a href="javascript:;"><img src={require('../../staticcdn2.maiziqianbao.net/static/img/partners images/2.png')} height="100%" /></a></li><li><a href="javascript:;"><img src={require('../../staticcdn2.maiziqianbao.net/static/img/partners images/3.png')} height="100%" /></a></li>
                            <li><a href="javascript:;"><img src={require('../../staticcdn2.maiziqianbao.net/static/img/partners images/4.png')} height="100%" /></a></li><li><a  rel="noreferrer"><img src={require('../../staticcdn2.maiziqianbao.net/static/img/partners images/5.png')} height="100%" /></a></li>
                            <li><a href="javascript:;"><img src={require('../../staticcdn2.maiziqianbao.net/static/img/partners images/6.png')} height="100%" /></a></li><li><a  rel="noreferrer"><img src={require('../../staticcdn2.maiziqianbao.net/static/img/partners images/7.png')} height="100%" /></a></li>
                            <li><a href="javascript:;"><img src={require('../../staticcdn2.maiziqianbao.net/static/img/partners images/8.png')} height="100%" /></a></li><li><a href="javascript:;"><img src={require('../../staticcdn2.maiziqianbao.net/static/img/partners images/9.png')} height="100%" /></a></li>

                        </ul></div></div></div>

                </section>
                <footer>
                    <Footer />
                    {/* <div className="container">
                        <div className="contact"><a href="https://twitter.com/Mathwallet" target="_blank" rel="noreferrer"><img src="https://staticcdn2.maiziqianbao.net/static/img/foot/twitter.png" width={32} /></a><a href="https://discord.gg/gXSfnk5eP5" target="_blank" rel="noreferrer"><img src="https://staticcdn2.maiziqianbao.net/static/img/foot/discord.png" width={32} /></a>
                            <a href="mailto:labs@mathwallet.org"><img src="https://staticcdn2.maiziqianbao.net/static/img/foot/email.png" width={32} /></a><a href="https://t.me/mathwalletnews" target="_blank" rel="noreferrer"><img src="https://staticcdn2.maiziqianbao.net/static/img/foot/telegram.png" width={32} /></a>
                            <a href="https://medium.com/@mathwallet" target="_blank" rel="noreferrer"><img src="https://staticcdn2.maiziqianbao.net/static/img/foot/medium.png" width={32} /></a><a href="https://www.youtube.com/c/MathWallet" target="_blank" rel="noreferrer"><img src="https://staticcdn2.maiziqianbao.net/static/img/foot/youtube.png" width={32} /></a>
                            <a href="https://github.com/mathwallet" target="_blank" rel="noreferrer"><img src="https://staticcdn2.maiziqianbao.net/static/img/foot/github.png" width={32} /></a>
                        </div>
                        <div className="copyright"><a target="_blank" rel="noreferrer" href="https://blog.mathwallet.org/?p=396">Download Media Kit</a></div>
                    </div> */}
                </footer>
            </div >

        </div >
    )
}

export default LandingPage