import LandingPage from "./fresh/mathwallet.org/en-us/LandingPage";
import '../src/common.bebaa1dc.css'
import '../src/index.cb8f1842.css'
function App() {
  return (
    <div className="App">
      <LandingPage />
    </div>
  );
}

export default App;
